import { Container } from "react-bootstrap"

function Footer() {
	return (
		<footer>
			<Container fluid className="text-center">
				<p className="text-uppercase">
					Registered Address: Level 33, 25 Canada Square, London, E14
					5LB Company Number: 07557993
				</p>
				<a
					href="mailto:info@myclinicaloutcomes.com"
					className="contact-link"
				>
					info@myclinicaloutcomes.com
				</a>
				<ul className="list-inline">
					<li className="list-inline-item">
						<a href="http://www.myclinicaloutcomes.com/content/privacy-policy">
							Privacy Policy
						</a>
					</li>
					<li className="list-inline-item">
						<a href="http://www.myclinicaloutcomes.com/content/cookie-policy">
							Cookie Policy
						</a>
					</li>
					<li className="list-inline-item">
						<a href="http://www.myclinicaloutcomes.com/content/terms-of-service">
							Terms of Service
						</a>
					</li>
				</ul>
			</Container>
		</footer>
	)
}

export default Footer

import { Button, Dropdown, Navbar, NavItem } from "react-bootstrap"
import logo from "../images/MCO-Logo-White-Text.png"
import { faBars } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

const CustomToggle = React.forwardRef(
	({ children, onClick }: any, ref: any) => (
		<Button variant="secondary" onClick={onClick}>
			{children}
		</Button>
	)
)

export function Header({
	setShowContactUsModal
}: {
	setShowContactUsModal: React.Dispatch<React.SetStateAction<boolean>>
}) {
	return (
		<header className="App-header">
			<Navbar bg="dark">
				<div className="text-center w-100">
					<Navbar.Brand href="https://www.myclinicaloutcomes.com">
						<img src={logo} alt="MCO Corporate Page" />
					</Navbar.Brand>
				</div>
				<Dropdown as={NavItem} className="d-sm-none float-right">
					<Dropdown.Toggle as={CustomToggle}>
						<FontAwesomeIcon icon={faBars} />
					</Dropdown.Toggle>
					<Dropdown.Menu align="right">
						<Dropdown.Item
							onClick={() => setShowContactUsModal(true)}
						>
							Contact Us
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
				;
			</Navbar>
		</header>
	)
}

import axios, { CancelTokenSource } from "axios"
import React, { useState } from "react"
import Autosuggest, {
	ChangeEvent,
	GetSuggestionValue,
	InputProps,
	OnSuggestionsClearRequested,
	RenderSuggestion,
	SuggestionsFetchRequested
} from "react-autosuggest"
import { Button, Form } from "react-bootstrap"
import { ContactSupport } from "./ContactSupport"

export function getApiUrl(uri: string) {
	const host = window.location.host

	const domain =
		host === "localhost:3002"
			? "localhost.com"
			: host.substring(host.indexOf(".") + 1)

	return `https://api.${domain}/${uri}`
}

async function get(
	uri: string,
	set: React.Dispatch<React.SetStateAction<any>>,
	[cancelTokenSource, setCancelTokenSource]: [
		CancelTokenSource,
		React.Dispatch<CancelTokenSource>
	]
) {
	cancelTokenSource?.cancel("Input changed. Cancelling request")

	const source = axios.CancelToken.source()
	setCancelTokenSource(source)

	try {
		const response = await axios.get(getApiUrl(uri), {
			cancelToken: source.token,
			headers: {
				"MCO-TENANT-NOT-REQUIRED": "true"
			}
		})
		set(response.data)
	} catch (ex) {
		if (axios.isCancel(ex)) {
			return
		}
		throw ex
	}
}

function LookupForm({
	showContactUsModal,
	setShowContactUsModal
}: {
	showContactUsModal: boolean
	setShowContactUsModal: React.Dispatch<React.SetStateAction<boolean>>
}) {
	const [treatmentCenterValue, setTreatmentCenterValue] = useState("")
	const [treatmentCenterSuggestions, setTreatmentCenterSuggestions] =
		useState({ count: 0, data: [] as string[] })
	const [loaded, setLoaded] = useState(false)
	const [suggestions, setSuggestions] = useState({
		count: 0,
		data: [] as { name: string; url: string }[]
	})

	const handleTreatmentCentreChange = (
		_: React.FormEvent<HTMLElement>,
		{ newValue }: ChangeEvent
	) => {
		setLoaded(false)
		setTreatmentCenterValue(newValue)

		if (treatmentCenterSuggestions.data.indexOf(newValue) === -1) {
			return
		}

		const searchParams = new URLSearchParams()
		searchParams.append("treatmentCenter", newValue)

		const uri = `api/start/suggestions?${searchParams.toString()}`

		return get(uri, setSuggestions, cancelTokenState)
	}

	const treatmentCenterAutoSuggestInputProps: InputProps<string> = {
		placeholder: "Enter Treatment Centre Name",
		value: treatmentCenterValue,
		onChange: handleTreatmentCentreChange,
		className: "form-control",
		name: "treatmentCentre"
	}

	const cancelTokenState = useState(undefined as unknown as CancelTokenSource)

	const handleTreatmentCenterSuggestionsRequested: SuggestionsFetchRequested =
		async ({ value }) => {
			setLoaded(false)
			setSuggestions({
				count: 0,
				data: []
			})
			if (value.length < 3) {
				return
			}
			const searchParams = new URLSearchParams()
			searchParams.append("search", value)

			const uri = `api/start/treatmentCenters?${searchParams.toString()}`

			const result = await get(
				uri,
				setTreatmentCenterSuggestions,
				cancelTokenState
			)
			setLoaded(true)
			return result
		}

	const handleTreatmentCenterSuggestionsClear: OnSuggestionsClearRequested =
		() => setTreatmentCenterSuggestions({ count: 0, data: [] })

	const getSuggestionValue: GetSuggestionValue<string> = (suggestion) => {
		return suggestion
	}

	const renderSuggestion: RenderSuggestion<string> = (suggestion, _) => {
		return <span>{suggestion}</span>
	}

	return (
		<Form>
			<Form.Group controlId="treatmentCentre">
				<Form.Label>Treatment Centre</Form.Label>
				<Autosuggest
					id="treatment-centre-suggestions"
					suggestions={treatmentCenterSuggestions.data}
					onSuggestionsFetchRequested={
						handleTreatmentCenterSuggestionsRequested
					}
					onSuggestionsClearRequested={
						handleTreatmentCenterSuggestionsClear
					}
					getSuggestionValue={getSuggestionValue}
					renderSuggestion={renderSuggestion}
					inputProps={treatmentCenterAutoSuggestInputProps}
				/>
			</Form.Group>
			{suggestions.data.length > 0 && (
				<Form.Group>
					<Form.Label>Suggestions</Form.Label>
					<ul>
						{suggestions.data.map((s) => (
							<li key={s.url}>
								<a
									href={`${s.url}/Registration?treatmentCenter=${treatmentCenterValue}`}
								>
									{s.name} ({s.url})
								</a>
							</li>
						))}
					</ul>
				</Form.Group>
			)}
			{loaded &&
				treatmentCenterValue &&
				treatmentCenterSuggestions.data.length === 0 &&
				suggestions.data.length === 0 && (
					<p>
						Unable to recognise that treatment centre. Please{" "}
						<Button
							className="p-0 align-baseline"
							variant="link"
							onClick={() => setShowContactUsModal(true)}
						>
							contact support
						</Button>
					</p>
				)}
			{suggestions.count > 0 && suggestions.data.length === 0 && (
				<p>Too many suggestions returned</p>
			)}
			<ContactSupport
				show={showContactUsModal}
				setShow={setShowContactUsModal}
				treatmentCenter={treatmentCenterValue}
			/>
		</Form>
	)
}

export default LookupForm
